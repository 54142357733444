export const To = {
  trailersMap: '/trailers',
  trailersList: '/trailers/list',
  trailerDetails: '/trailers/details',
  stationDetails: '/station_details',
  trailerTypes: '/trailer_types',
  reports: '/reports',
  parkings: '/parkings',
  parkingShowListTable: '/parking_show_list',
  parkingDetails: '/parkings/details',
  organizations: '/organizations',
  organizationDetails: '/organizations/details',
  parentOrganizations: '/parent_organizations',
  staff: '/staff',
  staffDetails: '/staff/details',
  changePassword: '/change_password',
  users: '/users',
  userDetails: '/users/details',
  price: '/price',
  inspection: '/inspection',
  promoCode: '/promo_code',
  trackerCodes: '/tracker_codes',
}
