enum StoreActions {
  IS_LOADER_SHOW = 'TRACKER_CODES/IS_LOADER_SHOW',
  IS_LOADER_HIDE = 'TRACKER_CODES/IS_LOADER_HIDE',
  SAVE_TRACKER_CODES = 'TRACKER_CODES/SAVE_TRACKER_CODES',
  FILTER_TRACKER_CODES = 'TRACKER_CODES/FILTER_TRACKER_CODES',
  UNSHIFT_TO_TRACKER_CODES = 'TRACKER_CODES/UNSHIFT_TO_TRACKER_CODES',
  RESET = 'TRACKER_CODES/RESET',
}

enum SagaEvents {
  LOAD_PAGE = 'TRACKER_CODES/LOAD_PAGE',
  CREATE_TRACKER_CODE = 'TRACKER_CODES/CREATE_TRACKER_CODE',
  DELETE_TRACKER_CODE = 'TRACKER_CODES/DELETE_TRACKER_CODE',
}

export const ActionTypes_TrackerCodes = {
  SagaEvents,
  StoreActions,
}
