import { ResendBill } from './ResendBill/ResendBill'
import { UserActivation } from './UserActivation/UserActivation'
import { ResetPassword } from './ResetPassword/ResetPassword'
import { StaffChangeRole } from './StaffChangeRole/StaffChangeRole'
import { StaffActivation } from './StaffActivation/StaffActivation'
import { TrailerDelete } from './TrailerDelete/TrailerDelete'
import { TrailerDeleteFromTrailersList } from './TrailerDeleteFromTrailersList/TrailerDeleteFromTrailersList'
import { ExitConfirm } from './ExitConfirm/ExitConfirm'
import { TrailerCreate } from './TrailerCreate/TrailerCreate'
import { ParkingCreate } from 'ui/modals/ParkingCreate/ParkingCreate'
import { StationCreate } from 'ui/modals/StationCreate/StationCreate'
import { StationUpdate } from 'ui/modals/StationUpdate/StationUpdate'
import { OrganizationCreate } from 'ui/modals/OrganizationCreate/OrganizationCreate'
import { OrganizationActivation } from 'ui/modals/OrganizationActivation/OrganizationActivation'
import { AddParkingToOrganization } from 'ui/modals/AddParkingToOrganization/AddParkingToOrganization'
import { AddTrailerToOrganization } from 'ui/modals/AddTrailerToOrganization/AddTrailerToOrganization'
import { RemoveParkingFromOrganizationConfirm } from 'ui/modals/RemoveParkingFromOrganizationConfirm/RemoveParkingFromOrganizationConfirm'
import { RemoveTrailerFromOrganizationConfirm } from 'ui/modals/RemoveTrailerFromOrganizationConfirm/RemoveTrailerFromOrganizationConfirm'
import { StaffCreate } from 'ui/modals/StaffCreate/StaffCreate'
import { StaffDelete } from 'ui/modals/StaffDelete/StaffDelete'
import { RemoveStaffFromStaffTable } from 'ui/modals/RemoveStaffFromStaffTable/RemoveStaffFromStaffTable'
import { DeleteParkingConfirm } from 'ui/modals/DeleteParkingConfirm/DeleteParkingConfirm'
import { CompleteRent } from 'ui/modals/CompleteRent/CompleteRent'
import { TrailerTypeActivation } from 'ui/modals/TrailerTypeActivation/TrailerTypeActivation'
import { TrailerTypeDelete } from 'ui/modals/TrailerTypeDelete/TrailerTypeDelete'
import { VerifyUser } from 'ui/modals/VerifyUser/VerifyUser'
import { UserDelete } from 'ui/modals/UserDelete/UserDelete'
import { RemoveUserFromUsersTable } from 'ui/modals/RemoveUserFromUsersTable/RemoveUserFromUsersTable'
import { LockStation } from 'ui/modals/LockStation/LockStation'
import { PromoCodeCreate } from 'ui/modals/PromoCodeCreate/PromoCodeCreate'
import { PromoCodeGenerate } from 'ui/modals/PromoCodeGenerate/PromoCodeGenerate'
import { PromoCodeUpdate } from 'ui/modals/PromoCodeUpdate/PromoCodeUpdate'
import { PromoCodeDelete } from 'ui/modals/PromoCodeDelete/PromoCodeDelete'
import { BreakdownHistory } from 'ui/modals/BreakdownHistory/BreakdownHistory'
import { OrganizationsFilter } from './OrganizationsFilter/OrganizationsFilter'
import { StatusFilter } from './StatusFilter/StatusFilter'
import { TrailerTypeFilter } from './TrailerTypeFilter/TrailerTypeFilter'
import { ViewTrailersTableColumns } from './ViewTrailersTableColumns/ViewTrailersTableColumns'
import { ParentOrganizationCreate } from './ParentOrganizationCreate/ParentOrganizationCreate'
import { ParentOrganizationUpdate } from './ParentOrganizationUpdate/ParentOrganizationUpdate'
import { ParentOrganizationActivation } from './ParentOrganizationActivation/OrganizationActivation'
import { UserDiscountDelete } from './UserDiscountDelete/UserDiscountDelete'
import { UserToOrganizationDiscountDelete } from './UserToOrganizationDiscountDelete/UserToOrganizationDiscountDelete'
import { UserToOrganizationDiscountCreate } from './UserToOrganizationDiscountCreate/UserToOrganizationDiscountCreate'
import { OrganizationInvitationCodesCreate } from './OrganizationInvitationCodesCreate/OrganizationInvitationCodesCreate'
import { PromoCodeGenerateFromExcel } from './PromoCodeGenerateFromExcel/PromoCodeGenerateFromExcel'
import DateFilter from './DateFilter/DateFilter'
import BonusMinutesFilter from './BonusMinutesFilter/BonusMinutesFilter'
import { ParkingNameFilter } from './ParkingNameFilter/ParkingNameFilter'
import { ParkingIMEIFilter } from './ParkingIMEIFilter/ParkingIMEIFilter'
import { OrganizationNameFilter } from './OrganizationNameFilter/OrganizationNameFilter'
import { BatteryChargeFilter } from './BatteryChargeFilter/BatteryChargeFilter'
import PromoCodeNameFilter from './PromoCodeNameFilter/PromoCodeNameFilter'
import { ParkingStatusFilter } from './ParkingStatusFilter/ParkingStatusFilter'
import { StationNameFilter } from './StationNameFilter/StationNameFilter'
import { StationIMEIFilter } from './StationIMEIFilter/StationIMEIFilter'
import { StationCodeFilter } from './StationCodeFilter/StationCodeFilter'
import { StationStatusFilter } from './StationStatusFilter/StationStatusFilter'
import { TrailerIMEIFilter } from './TrailerIMEIFilter/TrailerIMEIFilter'
import { TrailerGVMNumberFilter } from './TrailerGVMNumberFilter/TrailerGVMNumberFilter'
import { StationConnectionFilter } from './StationConnectionFilter/StationConnectionFilter'
import { StationLockFilter } from './StationLockFilter/StationLockFilter'
import { ParkingDelete } from './ParkingDelete/ParkingDelete'
import { StationOrganizationNameFilter } from './StationOrganizationNameFilter/StationOrganizationNameFilter'
import { StationsTrailerTypeFilter } from './StationsTrailerTypeFilter/StationsTrailerTypeFilter'
import { PromoCodeTrailerTypeFilter } from './PromoCodeTrailerTypeFilter/PromoCodeTrailerTypeFilter'
import { PromoCodeOrganizationsFilter } from './PromoCodeOrganizationsFilter/PromoCodeOrganizationsFilter'
import { Checklist } from './Checklist/Checklist'
import { Photo } from './Photo/Photo'
import { TrackerCodeDelete } from './TrackerCodeDelete/TrackerCodeDelete'
import { TrackerCodeCreate } from './TrackerCodeCreate/TrackerCodeCreate'

export const Modals = {
  UserActivation,
  ResendBill,
  StaffChangeRole,
  ResetPassword,
  TrailerDelete,
  TrailerDeleteFromTrailersList,
  TrailerTypeActivation,
  TrailerTypeDelete,
  StaffActivation,
  TrailerCreate,
  ExitConfirm,
  ParkingCreate,
  StationCreate,
  StationUpdate,
  OrganizationCreate,
  OrganizationActivation,
  AddParkingToOrganization,
  AddTrailerToOrganization,
  OrganizationInvitationCodesCreate,
  RemoveParkingFromOrganizationConfirm,
  RemoveTrailerFromOrganizationConfirm,
  ParentOrganizationActivation,
  ParentOrganizationCreate,
  ParentOrganizationUpdate,
  StaffCreate,
  StaffDelete,
  RemoveStaffFromStaffTable,
  DeleteParkingConfirm,
  CompleteRent,
  VerifyUser,
  UserDelete,
  UserDiscountDelete,
  UserToOrganizationDiscountCreate,
  UserToOrganizationDiscountDelete,
  RemoveUserFromUsersTable,
  LockStation,
  PromoCodeCreate,
  PromoCodeGenerate,
  PromoCodeGenerateFromExcel,
  PromoCodeUpdate,
  PromoCodeDelete,
  BreakdownHistory,
  OrganizationsFilter,
  StatusFilter,
  TrailerTypeFilter,
  ViewTrailersTableColumns,
  DateFilter,
  BonusMinutesFilter,
  PromoCodeNameFilter,
  ParkingNameFilter,
  ParkingIMEIFilter,
  OrganizationNameFilter,
  BatteryChargeFilter,
  ParkingStatusFilter,
  StationNameFilter,
  StationIMEIFilter,
  StationCodeFilter,
  StationStatusFilter,
  TrailerIMEIFilter,
  TrailerGVMNumberFilter,
  StationConnectionFilter,
  StationLockFilter,
  ParkingDelete,
  StationOrganizationNameFilter,
  StationsTrailerTypeFilter,
  PromoCodeTrailerTypeFilter,
  PromoCodeOrganizationsFilter,
  Checklist,
  Photo,
  TrackerCodeDelete,
  TrackerCodeCreate,
}

export type TModals = typeof Modals
