import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { TState } from 'redux/config/root-types'
import { useEffect, useMemo } from 'react'
import { Actions_Auth } from 'redux/modules/auth/Actions'
import { useRoutes } from './Routes'
import { useTheme } from '@mui/material/styles'
import { AuthStorage } from 'utils/helpers/auth'
import { useAuthRights } from 'ui/hooks/useAuthRights'

export const useAuthorization = () => {
  const dispatch = useDispatch()
  const theme = useTheme()
  const { authentificatedRoute, isInitialized } = useSelector(
    ({ auth, redirect, meRole }: TState) => ({
      authentificatedRoute: redirect.data,
      isToken: auth.data,
      isInitialized: meRole.isInitialized,
    }),
    shallowEqual,
  )
  const {
    canTrailerView,
    canTrailerListView,
    canTrailerDetailsView,
    canTrailerTypeView,
    canStaffView,
    canStaffDetailsView,
    canOrganizationView,
    canOrganizationDetailsView,
    canParentOrganizationView,
    canParkingView,
    canParkingDetailsView,
    canParkingShowListView,
    canPriceView,
    canInspectionView,
    canReportView,
    canUserView,
    canUserDetailsView,
    canPromocodeView,
    canTrackerCodesView,
  } = useAuthRights()

  const rules = {
    canTrailerView,
    canTrailerListView,
    canTrailerDetailsView,
    canTrailerTypeView,
    canStaffView,
    canStaffDetailsView,
    canOrganizationView,
    canOrganizationDetailsView,
    canParentOrganizationView,
    canParkingView,
    canParkingDetailsView,
    canParkingShowListView,
    canPriceView,
    canInspectionView,
    canReportView,
    canUserView,
    canUserDetailsView,
    canPromocodeView,
    canTrackerCodesView,
  }

  // eslint-disable-next-line
  const isAuthorized: boolean = useMemo(() => AuthStorage.isAuthorized, [AuthStorage.isAuthorized])

  useEffect(
    function loadInitialData() {
      if (isAuthorized) {
        dispatch(Actions_Auth.saga.getInitialData())
      }
    },
    [isAuthorized, dispatch],
  )

  const routes = useRoutes({ isAuthentificated: isAuthorized, authentificatedRoute, isInitialized, rules })

  return { routes, theme }
}
