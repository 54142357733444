import { call, put } from 'redux-saga/effects'
import { RequestMethods } from 'utils/axios/methods'
import type { PayloadAction } from '@reduxjs/toolkit'
import { Actions_Modals } from 'redux/modules/modals/Actions'
import { Actions_Notifications } from 'redux/modules/notification/Actions'
import { sagaAction_Error } from 'redux/sagas/actions/SagaAction_Error'
import { TParentOrganization } from 'redux/modules/parentOrganizations/TReducer'
import { TStoreTemplate } from 'redux/config/root-types'
import { TTrackerCode } from 'redux/modules/trackerCodes/TReducer'
import { Actions_TrackerCodes } from 'redux/modules/trackerCodes/Actions'

const { post } = RequestMethods()

export interface ISagaWorker_CreateTrackerCode {
  imei: string
}

export default function* SagaWorker_CreateTrackerCode(action: PayloadAction<ISagaWorker_CreateTrackerCode>) {
  yield put(Actions_TrackerCodes.store.showLoader())

  try {
    const newCode: TStoreTemplate<TTrackerCode> = yield call(
      async () => await post<TParentOrganization>(`/user_trailers/code`, action.payload),
    )
    if (newCode.data) {
      yield put(Actions_TrackerCodes.store.unshiftToTrackerCodes(newCode.data))
    }

    yield put(Actions_Modals.store.hideModal())

    yield put(
      Actions_Notifications.store.setNotification({
        message: `Successfully created new code: ${newCode.data?.code || ''}`,
        type: 'Success',
      }),
    )
  } catch (e) {
    yield call(() => sagaAction_Error(e))
  } finally {
    yield put(Actions_TrackerCodes.store.hideLoader())
  }
}
