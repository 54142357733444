import React, { FC } from 'react'
import { Shared } from 'ui/shared'
import Grid from '@mui/material/Grid'
import { useTrackerCodeCreate } from './TrackerCodeCreate-ViewModel'

export const TrackerCodeCreate: FC = () => {
  const vm = useTrackerCodeCreate()
  const { closeModal, form, isLoading, t } = vm
  const { errors, handleSubmit, register, formValidation } = form

  return (
    <Shared.Card style={{ width: 375, overflowY: 'auto', maxHeight: '100%' }}>
      <form autoComplete="off" onSubmit={handleSubmit}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Shared.CardTitle title={t('title')} />
            <Shared.InputField
              name="imei"
              title={t('imei')}
              register={register(formValidation.imei)}
              error={errors.imei?.message}
            />
          </Grid>

          <Grid item xs={12}>
            <Shared.Button
              style={{ margin: '0.875em 0 0.875em 0' }}
              color={'secondary'}
              title={t('cancel')}
              onClick={closeModal}
            />
            <Shared.Button title={t('submit')} type={'submit'} disabled={isLoading} />
          </Grid>

          {isLoading && <Shared.Preloader mask />}
        </Grid>
      </form>
    </Shared.Card>
  )
}
