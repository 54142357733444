import { useDispatch, useSelector } from 'react-redux'
import { TState } from 'redux/config/root-types'
import { useForm } from 'react-hook-form'
import { Actions_Modals } from 'redux/modules/modals/Actions'
import { useTranslation } from 'react-i18next'
import { I18nModal } from 'utils/i18n/i18nModal'
import { Form } from 'utils/helpers/form'
import { Actions_TrackerCodes } from 'redux/modules/trackerCodes/Actions'

interface IForm {
  imei: string
}

const formValidation = {
  imei: Form.required,
}

export const useTrackerCodeCreate = () => {
  const { t } = useTranslation(I18nModal.trackerCodeCreate)
  const dispatch = useDispatch()

  const isLoading = useSelector(({ trackerCodes }: TState) => trackerCodes.isLoading)

  const { handleSubmit, register, errors } = useForm<IForm>({
    defaultValues: {
      imei: '',
    },
  })

  const onSubmit = (formData: IForm) => {
    const data = {
      ...formData,
    }
    dispatch(Actions_TrackerCodes.saga.createTrackerCode(data))
  }

  const closeModal = () => {
    dispatch(Actions_Modals.store.hideModal())
  }

  return {
    t,
    closeModal,
    isLoading,
    form: {
      formValidation,
      errors,
      register,
      handleSubmit: handleSubmit(onSubmit),
    },
  }
}
