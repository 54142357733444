import { call, put } from 'redux-saga/effects'
import { RequestMethods } from 'utils/axios/methods'
import type { PayloadAction } from '@reduxjs/toolkit'
import { Actions_Modals } from 'redux/modules/modals/Actions'
import { Actions_Notifications } from 'redux/modules/notification/Actions'
import { sagaAction_Error } from 'redux/sagas/actions/SagaAction_Error'
import { TPromoCode } from 'redux/modules/promoCode/TReducer'
import { Actions_TrackerCodes } from 'redux/modules/trackerCodes/Actions'

const { remove } = RequestMethods()

export interface ISagaWorker_DeleteTrackerCode {
  id: number
}

export default function* SagaWorker_DeleteTrackerCode(action: PayloadAction<ISagaWorker_DeleteTrackerCode>) {
  const { id } = action.payload
  yield put(Actions_TrackerCodes.store.showLoader())

  try {
    yield call(async () => await remove<TPromoCode>(`/user_trailers/code/${id}`))

    yield put(Actions_TrackerCodes.store.filterTrackerCodes(id))

    yield put(Actions_Modals.store.hideModal())

    yield put(
      Actions_Notifications.store.setNotification({ message: 'Tracker code successfully deleted', type: 'Success' }),
    )
  } catch (e) {
    yield call(() => sagaAction_Error(e))
  } finally {
    yield put(Actions_TrackerCodes.store.hideLoader())
  }
}
