import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { TState } from 'redux/config/root-types'

export type TAuthRights = ReturnType<typeof useAuthRights>

export const useAuthRights = () => {
  const role = useSelector((state: TState) => state.meRole.data?.role)
  //console.log(role)

  return useMemo(() => {
    const hasFullAccess = Boolean(role?.hasFullAccess)

    return {
      hasFullAccess,

      canTrailerView: Boolean(hasFullAccess || role?.TRAILER_VIEW),
      canTrailerCreate: Boolean(hasFullAccess || role?.TRAILER_CREATE),
      canTrailerViewTenant: Boolean(hasFullAccess || role?.TRAILER_VIEW_TENANT),

      canTrailerListView: Boolean(hasFullAccess || true), // TODO: add role

      canTrailerDetailsView: Boolean(hasFullAccess || role?.TRAILER_DETAILS_VIEW),
      canTrailerDetailsEdit: Boolean(hasFullAccess || role?.TRAILER_DETAILS_EDIT),
      canTrailerDetailsDeactivate: Boolean(hasFullAccess || role?.TRAILER_DETAILS_DEACTIVATE),
      canTrailerDetailsDelete: Boolean(hasFullAccess || role?.TRAILER_DETAILS_DELETE),
      canTrailerDetailsRentHistory: Boolean(hasFullAccess || role?.TRAILER_DETAILS_RENT_HISTORY),
      canTrailerDetailsExportXlsRentHistory: Boolean(hasFullAccess || role?.TRAILER_DETAILS_EXPORT_XLS_RENT_HISTORY),
      canTrailerDetailsTotalPaymentView: Boolean(hasFullAccess || role?.TRAILER_DETAILS_TOTAL_PAYMENT_VIEW),
      canTrailerDetailsBotAccess: Boolean(hasFullAccess || role?.TRAILER_DETAILS_BOT_ACCESS),
      canTrailerDetailsInspectionView: Boolean(hasFullAccess || role?.TRAILER_DETAILS_INSPECTION_VIEW),
      canTrailerDetailsInspectionEdit: Boolean(hasFullAccess || role?.TRAILER_DETAILS_INSPECTION_EDIT),
      canTrailerDetailsNotesView: Boolean(hasFullAccess || role?.TRAILER_DETAILS_NOTES_VIEW),
      canTrailerDetailsNotesDelete: false && Boolean(hasFullAccess || role?.TRAILER_DETAILS_NOTES_DELETE), // TODO: open when the delete notes functionality is added

      canTrailerTypeView: Boolean(hasFullAccess || role?.TRAILER_TYPE_VIEW),
      canTrailerTypeEdit: Boolean(hasFullAccess || role?.TRAILER_TYPE_EDIT),
      canTrailerTypeDeactivate: Boolean(hasFullAccess || role?.TRAILER_TYPE_DEACTIVATE),
      canTrailerTypeDelete: true || Boolean(hasFullAccess || role?.TRAILER_TYPE_DELETE), // add on server

      canParkingView: Boolean(hasFullAccess || role?.PARKING_VIEW),
      canParkingCreate: Boolean(hasFullAccess || role?.PARKING_CREATE),
      canParkingStationCreate: Boolean(hasFullAccess || role?.PARKING_STATION_CREATE),
      canParkingStationList: Boolean(hasFullAccess || role?.PARKING_STATION_LIST),
      canParkingShowListView: Boolean(hasFullAccess || role?.PARKING_DETAILS_VIEW),

      canParkingDetailsView: Boolean(hasFullAccess || role?.PARKING_DETAILS_VIEW),
      canParkingDetailsEdit: Boolean(hasFullAccess || role?.PARKING_DETAILS_EDIT),
      canParkingDetailsDeactivate: Boolean(hasFullAccess || role?.PARKING_DETAILS_DEACTIVATE),
      canParkingDetailsDelete: Boolean(hasFullAccess || role?.PARKING_DETAILS_DELETE),
      canParkingDetailsExportXLS: Boolean(hasFullAccess || role?.PARKING_DETAILS_EXPORT_XLS),
      canParkingDetailsNotesView: Boolean(hasFullAccess || role?.PARKING_DETAILS_NOTES_VIEW),
      canParkingDetailsNotesDelete: false && Boolean(hasFullAccess || role?.PARKING_DETAILS_NOTES_DELETE), // TODO: open when the delete notes functionality is added

      canStationDetailsView: Boolean(hasFullAccess || role?.STATION_DETAILS_VIEW),
      canStationDetailsRentHistory: Boolean(hasFullAccess || role?.STATION_DETAILS_RENT_HISTORY),
      canStationDetailsExportXLSRentHistory: Boolean(hasFullAccess || role?.STATION_DETAILS_EXPORT_XLS_RENT_HISTORY),
      canStationDetailsEdit: Boolean(hasFullAccess || role?.STATION_DETAILS_EDIT),
      canStationDetailsDeactivate: Boolean(hasFullAccess || role?.STATION_DETAILS_DEACTIVATE),

      canStaffView: Boolean(hasFullAccess || role?.STAFF_VIEW),
      canStaffCreate: Boolean(hasFullAccess || role?.STAFF_CREATE),

      canStaffDetailsView: Boolean(hasFullAccess || role?.STAFF_DETAILS_VIEW),
      canStaffDetailsDeactivate: Boolean(hasFullAccess || role?.STAFF_DETAILS_DEACTIVATE),
      canStaffDetailsResetPassword: Boolean(hasFullAccess || role?.STAFF_DETAILS_RESET_PASSWORD),
      canStaffDetailsEdit: Boolean(hasFullAccess || role?.STAFF_DETAILS_EDIT),
      canStaffDetailsDelete: Boolean(hasFullAccess || true), // TODO: add role?.STAFF_DETAILS_DELETE

      canOrganizationView: Boolean(hasFullAccess || role?.ORGANIZATION_VIEW),
      canOrganizationCreate: Boolean(hasFullAccess || role?.ORGANIZATION_CREATE),
      canOrganizationExportXLS: Boolean(hasFullAccess || role?.ORGANIZATION_EXPORT_XLS),

      canOrganizationDetailsView: Boolean(hasFullAccess || role?.ORGANIZATION_DETAILS_VIEW),
      canOrganizationDetailsEdit: Boolean(hasFullAccess || role?.ORGANIZATION_EDIT),
      canOrganizationDetailsTrailerView: Boolean(hasFullAccess || role?.ORGANIZATION_TRAILER_VIEW),
      canOrganizationDetailsTrailerExportXLS: Boolean(hasFullAccess || role?.ORGANIZATION_TRAILER_EXPORT_XLS),
      canOrganizationDetailsDeactivate: Boolean(hasFullAccess || role?.ORGANIZATION_DEACTIVATE),
      canOrganizationInvitationCodesCreate: Boolean(hasFullAccess || true), // TODO: add role
      canOrganizationInvitationCodesExportXLS: Boolean(hasFullAccess || true), // TODO: add role

      canParentOrganizationView: Boolean(hasFullAccess || role?.PARENT_ORGANIZATION_VIEW),
      canParentOrganizationCreate: Boolean(hasFullAccess || role?.PARENT_ORGANIZATION_CREATE),
      canParentOrganizationUpdate: Boolean(hasFullAccess || role?.PARENT_ORGANIZATION_UPDATE),
      canParentOrganizationDeactivate: Boolean(hasFullAccess || role?.PARENT_ORGANIZATION_DEACTIVATE),

      canUserView: Boolean(hasFullAccess || role?.USER_VIEW),
      canUserDeactivate: Boolean(hasFullAccess || role?.USER_DEACTIVATE),
      canUsersExportXLS: true || Boolean(hasFullAccess || role?.USERS_EXPORT_XLS), // add on server

      canUserDetailsView: Boolean(hasFullAccess || role?.USER_DETAILS_VIEW),
      canUserDetailsRentHistory: Boolean(hasFullAccess || role?.USER_DETAILS_RENT_HISTORY),
      canUserDetailsExportXLSRentHistory: Boolean(hasFullAccess || role?.USER_DETAILS_EXPORT_XLS_RENT_HISTORY),
      canUserDetailsResendBill: Boolean(hasFullAccess || role?.USER_DETAILS_RESEND_BILL),
      canUserDetailsDelete: Boolean(hasFullAccess || true), // TODO: add role?.USER_DETAILS_DELETE
      canUserDetailsNotesView: Boolean(hasFullAccess || role?.USER_DETAILS_NOTES_VIEW),
      canUserDetailsNotesDelete: false && Boolean(hasFullAccess || role?.USER_DETAILS_NOTES_DELETE), // TODO: open when the delete notes functionality is added
      canUserDetailsDiscountTable: Boolean(hasFullAccess || true), // TODO: add role
      canUserDetailsOrganiztionDiscountCreate: Boolean(hasFullAccess || true), // TODO: add role
      canUserDetailsOrganiztionDiscountDelete: Boolean(hasFullAccess || true), // TODO: add role

      canPriceView: false && Boolean(hasFullAccess || role?.PRICE_VIEW), // TODO: open in future
      canPriceTabView: Boolean(hasFullAccess || role?.PRICE_PRICE_TAB_VIEW),
      canPriceCreate: Boolean(hasFullAccess || role?.PRICE_PRICE_CREATE),
      canPriceDiscountTabView: Boolean(hasFullAccess || role?.PRICE_DISCOUNT_TAB_VIEW),
      canPriceDiscountCreate: Boolean(hasFullAccess || role?.PRICE_DISCOUNT_CREATE),
      canPriceEdit: Boolean(hasFullAccess || role?.PRICE_PRICE_EDIT),
      canPriceDiscountEdit: Boolean(hasFullAccess || role?.PRICE_DISCOUNT_EDIT),

      canReportView: Boolean(hasFullAccess || role?.REPORT_VIEW),
      canReportTotalCost: Boolean(hasFullAccess || role?.REPORT_TOTAL_COST),
      canReportTotalNumber: Boolean(hasFullAccess || role?.REPORT_TOTAL_NUMBER),
      canReportTotalFrequency: Boolean(hasFullAccess || role?.REPORT_TOTAL_FREQUENCY),
      canReportTotalTime: Boolean(hasFullAccess || role?.REPORT_TOTAL_TIME),
      canReportRentHistoryCostData: Boolean(hasFullAccess || role?.REPORT_RENT_HISTORY_COST_DATA),

      canInspectionView: Boolean(hasFullAccess || role?.INSPECTION_VIEW),
      canInspectionEdit: Boolean(hasFullAccess || role?.INSPECTION_EDIT),

      canDiscountView: Boolean(hasFullAccess || role?.DISCOUNT_VIEW),
      canDiscountEdit: Boolean(hasFullAccess || role?.DISCOUNT_EDIT),

      canPromocodeView: Boolean(hasFullAccess || role?.PROMO_CODE_VIEW),
      canPromoCodeCreate: Boolean(hasFullAccess || role?.PROMO_CODE_CREATE),
      canPromoCodeUpdate: Boolean(hasFullAccess || role?.PROMO_CODE_UPDATE),
      canPromoCodeDelete: Boolean(hasFullAccess || role?.PROMO_CODE_DELETE),

      canTrackerCodesView: Boolean(hasFullAccess || role?.TRACKER_CODE_VIEW),
      canTrackerCodesCreate: Boolean(hasFullAccess || role?.TRACKER_CODE_CREATE),
      canTrackerCodesDelete: Boolean(hasFullAccess || role?.TRACKER_CODE_DELETE),
    }
  }, [role])
}
