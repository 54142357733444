import { useMemo } from 'react'
import { MenuIcons } from 'assets/images/MenuIcons'
import { useAuthRights } from 'ui/hooks/useAuthRights'
import { To } from 'router/To'
import { SearchIcons } from 'assets/images/SearchIcons'

const menuTitles = [
  { route: To.trailersMap, title: 'Trailers', icon: MenuIcons.Trailers },
  { route: To.trailerTypes, title: 'Trailer types', icon: MenuIcons.TrailerTypes },
  { route: To.staff, title: 'Staff', icon: MenuIcons.Staff },
  { route: To.organizations, title: 'Organizations', icon: MenuIcons.Organizations },
  { route: To.parentOrganizations, title: 'Parent organizations', icon: MenuIcons.ParentOrganizations },
  { route: To.parkings, title: 'Parkings', icon: MenuIcons.Stations },
  { route: To.users, title: 'Users', icon: MenuIcons.Users },
  { route: To.price, title: 'Price', icon: MenuIcons.Price },
  { route: To.inspection, title: 'Inspection', icon: MenuIcons.Inspection },
  { route: To.reports, title: 'Reports', icon: MenuIcons.Reports },
  { route: To.promoCode, title: 'Promo codes', icon: MenuIcons.PromoCodes },
  { route: To.trackerCodes, title: 'Tracker codes', icon: SearchIcons.Station },
]

export const useMenuList = () => {
  const {
    canTrailerView,
    canTrailerTypeView,
    canStaffView,
    canOrganizationView,
    canParentOrganizationView,
    canUserView,
    canParkingView,
    canPriceView,
    canInspectionView,
    canReportView,
    canPromocodeView,
    canTrackerCodesView,
  } = useAuthRights()

  const menuList = useMemo(
    () =>
      toMenuListData(
        canTrailerView,
        canTrailerTypeView,
        canStaffView,
        canOrganizationView,
        canParentOrganizationView,
        canUserView,
        canParkingView,
        canPriceView,
        canInspectionView,
        canReportView,
        canPromocodeView,
        canTrackerCodesView,
      ),
    [
      canTrailerView,
      canTrailerTypeView,
      canStaffView,
      canOrganizationView,
      canParentOrganizationView,
      canUserView,
      canParkingView,
      canPriceView,
      canInspectionView,
      canReportView,
      canPromocodeView,
      canTrackerCodesView,
    ],
  )

  return {
    data: menuList,
  }
}

const toMenuListData = (
  //TODO: make it better
  canTrailerView: boolean,
  canTrailerTypeView: boolean,
  canStaffView: boolean,
  canOrganizationView: boolean,
  canParentOrganizationView: boolean,
  canUserView: boolean,
  canParkingView: boolean,
  canPriceView: boolean,
  canInspectionView: boolean,
  canReportView: boolean,
  canPromocodeView: boolean,
  canTrackerCodesView: boolean,
) => {
  const menuList = []
  canTrailerView && menuList.push(menuTitles[0])
  canTrailerTypeView && menuList.push(menuTitles[1])
  canStaffView && menuList.push(menuTitles[2])
  canOrganizationView && menuList.push(menuTitles[3])
  canParentOrganizationView && menuList.push(menuTitles[4])
  canParkingView && menuList.push(menuTitles[5])
  canUserView && menuList.push(menuTitles[6])
  canPriceView && menuList.push(menuTitles[7])
  canInspectionView && menuList.push(menuTitles[8])
  canReportView && menuList.push(menuTitles[9])
  canPromocodeView && menuList.push(menuTitles[10])
  canTrackerCodesView && menuList.push(menuTitles[11])
  return menuList
}
