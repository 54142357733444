import { createAction } from '@reduxjs/toolkit'
import { ActionTypes_TrackerCodes } from './TActions'
import { TTrackerCode } from './TReducer'
import { TStoreTemplate } from 'redux/config/root-types'
import { ISagaWorker_DeleteTrackerCode } from 'redux/sagas/events/trackerCodes/SagaWorker_DeleteTrackerCode'
import { ISagaWorker_CreateTrackerCode } from 'redux/sagas/events/trackerCodes/SagaWorker_CreateTrackerCode'

const { StoreActions, SagaEvents } = ActionTypes_TrackerCodes

export const Actions_TrackerCodes = {
  saga: {
    loadPage: createAction(SagaEvents.LOAD_PAGE),
    createTrackerCode: createAction<ISagaWorker_CreateTrackerCode>(SagaEvents.CREATE_TRACKER_CODE),
    deleteTrackerCode: createAction<ISagaWorker_DeleteTrackerCode>(SagaEvents.DELETE_TRACKER_CODE),
  },

  store: {
    showLoader: createAction(StoreActions.IS_LOADER_SHOW),
    hideLoader: createAction(StoreActions.IS_LOADER_HIDE),
    saveTrackerCodes: createAction<TStoreTemplate<TTrackerCode[]>>(StoreActions.SAVE_TRACKER_CODES),
    filterTrackerCodes: createAction<number>(StoreActions.FILTER_TRACKER_CODES),
    unshiftToTrackerCodes: createAction<TTrackerCode>(StoreActions.UNSHIFT_TO_TRACKER_CODES),
    reset: createAction(StoreActions.RESET),
  },
}
