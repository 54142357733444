import { RequestMethods } from 'utils/axios/methods'
import { call, put } from 'redux-saga/effects'
import { Actions_PageLoader } from 'redux/modules/pageloader/Actions'
import { sagaAction_Error } from 'redux/sagas/actions/SagaAction_Error'
import { TStoreTemplate } from 'redux/config/root-types'
import { Actions_TrackerCodes } from 'redux/modules/trackerCodes/Actions'
import { TTrackerCode } from 'redux/modules/trackerCodes/TReducer'

const { get } = RequestMethods()

export default function* SagaPage_TrackerCodes() {
  try {
    yield put(Actions_PageLoader.store.showLoader())
    //------------------------- Getting and saving parent organizations ----------------------//

    const codes: TStoreTemplate<TTrackerCode[]> = yield call(
      async () => await get<TTrackerCode[]>('/user_trailers/codes'),
    )

    yield put(Actions_TrackerCodes.store.saveTrackerCodes(codes))
  } catch (e) {
    yield call(() => sagaAction_Error(e))
  } finally {
    yield put(Actions_PageLoader.store.hideLoader())
  }
}
